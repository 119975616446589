// Dependencias
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import { LOCALSTORAGE_KEY_LOGIN } from '../lib/Authentication';

// vue router
Vue.use(VueRouter);

// Deinição das rotas
const routes = [
  {
    path: '/',
    name: 'intro',
    component: Home,
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Home',
      subtitle: 'Seja bem-vindo ao Configurador',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Home',
      subtitle: 'Seja bem-vindo ao Configurador',
    },
  },
  {
    path: '/autenticacao',
    name: 'authentication',
    component: () => import('../views/Autenticacao.vue'),
    meta: {
      requiresAuth: false,
      showSidebar: false,
    },
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('../views/Templates.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Templates',
      subtitle: 'Templates pré definidos para geração de configurações de cliente',
    },
  },
  {
    path: '/clientes',
    name: 'customers',
    component: () => import('../views/Clientes.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Clientes',
      subtitle: 'Clientes que utilizam os serviços do Configurador',
    },
  },
  {
    path: '/configuracoes',
    name: 'settings',
    component: () => import('../views/Configuracoes.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Configurações',
      subtitle: 'Configurações de clientes para o Configurador',
    },
  },
  {
    path: '/configuracoes/*',
    name: 'filteredSettings',
    component: () => import('../views/Configuracoes.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Configurações',
      subtitle: 'Configurações de clientes para o Configurador',
    },
  },
  {
    path: '/upload',
    name: 'uploadFile',
    component: () => import('../views/Upload.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Upload de arquivos',
      subtitle: 'Área para fazer upload de arquivos',
    },
  },
  {
    path: '/dump',
    name: 'dump',
    component: () => import('../views/Dump.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Dump de banco de dados',
      subtitle: 'Área para fazer o dump de um banco de dados',
    },
  },
  {
    path: '/*',
    name: 'pageNotFound',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      requiresAuth: false,
      showSidebar: false,
    },
  },
];

// Criação do router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Verificação pré rota
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Requer autenticação
    if (!localStorage.getItem(LOCALSTORAGE_KEY_LOGIN)) {
      next({ name: 'authentication' });
    } else {
      next();
    }
  } else {
    // Não requer autenticação
    next();
  }
});

// Exportando router
export default router;
